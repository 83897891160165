import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  CalendarYearAvailableResponseDto,
  CalendarYearClosePeriodRequestDto,
  CalendarYearModuleResponseDto,
} from "@/models/interface/calendar-yearly";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class CalendarYearlyService extends HttpClient {
  /**
   * @description get available year
   */
  getAvailableYear(
    params?: RequestQueryParamsModel
  ): Promise<CalendarYearAvailableResponseDto[]> {
    return this.get<CalendarYearAvailableResponseDto[]>(
      Api.OpenClosePeriodYearAvailableList,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description get module by years period
   */
  getModules(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<CalendarYearModuleResponseDto>> {
    return this.get<Pagination<CalendarYearModuleResponseDto>>(
      Api.OpenClosePeriodYearModules,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description open or close period by year
   */
  toggleOpenPeriod(payload: CalendarYearClosePeriodRequestDto) {
    return this.put<boolean, CalendarYearClosePeriodRequestDto>(
      Api.OpenClosePeriodYear,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const calendarYearlyService = new CalendarYearlyService();
