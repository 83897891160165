























































































































import { SearchBuilder } from "@/builder";
import { useDate } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { CalendarYearClosePeriodRequestDto } from "@/models/interface/calendar-yearly";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { calendarYearlyService } from "@/services/calendar-yearly.service";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import Vue from "vue";

type FormState = {
  startYear: string | undefined;
  endYear: string | undefined;
};
type Record = {
  year: string;
  isOpen: boolean;
  modules: string[];
  isLoading: boolean;
};

export default Vue.extend({
  name: "IndexPage",
  mixins: [MNotification],
  data() {
    return {
      PAGE_SIZE_OPTIONS,
      formState: {
        startYear: undefined,
        endYear: undefined,
      } as FormState,
      validationSchema: {
        years: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
      },
      form: this.$refs.form as FormModel,
      startYearOptions: [] as Option[],
      endYearOptions: [] as Option[],
      periods: [] as Record[],
      loading: {
        year: false,
        find: false,
      },
      pagination: {
        totalElements: 0,
        limit: DEFAULT_PAGE_SIZE,
        page: DEFAULT_PAGE,
      },
    };
  },
  mounted() {
    if (this.$refs.form) {
      this.form = this.$refs.form as FormModel;
    }

    this.fetchAvailableYearList({ sorts: "year:asc" });
  },
  methods: {
    buildParams(state: FormState): RequestQueryParamsModel {
      const { toStartYear, toEndYear } = useDate();
      const params = new RequestQueryParams();
      params.sorts = "date:asc";
      const queries: string[] = [];

      if (state.startYear) {
        const startYear = toStartYear(
          moment().set({
            year: Number(state.startYear),
          })
        );
        const queryStartYear = new SearchBuilder()
          .push(["date", startYear.format()], { het: true })
          .build();
        queries.push(queryStartYear);
      }

      if (state.endYear) {
        const endYear = toEndYear(
          moment().set({
            year: Number(state.endYear),
          })
        );
        const queryEndYear = new SearchBuilder()
          .push(["date", endYear.format()], { let: true })
          .build();
        queries.push(queryEndYear);
      }

      params.search = queries.join(SearchBuilder.AND);
      return params;
    },
    handleSubmit() {
      const params = this.buildParams(this.formState);
      this.pagination.page = DEFAULT_PAGE;
      this.fetchModules(params);
    },
    async fetchModules(params?: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading.find = true;
        const response = await calendarYearlyService.getModules(params);
        this.periods = response.data.map(item => ({
          isOpen: item.isOpen,
          modules: item.module.split(";").map(mod => mod),
          year: item.year,
          isLoading: false,
        }));
        this.pagination.totalElements = response.totalElements;
      } catch {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loading.find = false;
      }
    },
    async fetchAvailableYearList(params?: RequestQueryParamsModel) {
      try {
        this.loading.year = true;
        const response = await calendarYearlyService.getAvailableYear(params);
        const options = response.map(({ year }) => ({
          key: year,
          value: year,
          label: year,
          disabled: false,
        }));
        this.startYearOptions = options;
        this.endYearOptions = options;
      } catch {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loading.year = false;
      }
    },
    handleReset() {
      this.form.resetFields();
      this.periods = [];
    },
    buildRequestDto(record: Record): CalendarYearClosePeriodRequestDto {
      return {
        isOpen: record.isOpen,
        year: record.year,
      };
    },
    async fetchToggleOpen(record: Record) {
      try {
        record.isLoading = true;
        const request: CalendarYearClosePeriodRequestDto = {
          isOpen: record.isOpen,
          year: record.year,
        };
        await calendarYearlyService.toggleOpenPeriod(request);
        const label = record.isOpen
          ? "notif_open_period_success_year"
          : "notif_close_period_success_year";
        this.showNotifSuccess(label, { year: record.year });
      } catch {
        this.showNotifError("notif_process_fail");
      } finally {
        record.isLoading = false;
      }
    },
    toggleOpen(record: Record, value: boolean) {
      const content = record.isOpen
        ? this.$t("confirmation.toggle-open-close-period-text", {
            text: this.$t("lbl_close"),
          })
        : this.$t("confirmation.toggle-open-close-period-text", {
            text: this.$t("lbl_open"),
          });
      this.showConfirmation(content, async () => {
        await this.fetchToggleOpen({ ...record, isOpen: value });
        const params = this.buildParams(this.formState);
        params.page = this.pagination.page - 1;
        params.limit = this.pagination.limit;
        this.fetchModules(params);
      });
    },
    onPaginationChange(page: number, pageSize: number) {
      this.pagination.page =
        pageSize !== this.pagination.limit ? DEFAULT_PAGE : page;
      this.pagination.limit = pageSize;

      const params = this.buildParams(this.formState);
      params.page = this.pagination.page - 1;
      params.limit = this.pagination.limit;
      this.fetchModules(params);
    },
    onPaginationSizeChange(_: number, size: number) {
      this.pagination.limit = size;
      this.pagination.page = DEFAULT_PAGE;

      const params = this.buildParams(this.formState);
      params.page = this.pagination.page - 1;
      params.limit = this.pagination.limit;
      this.fetchModules(params);
    },
  },
});
