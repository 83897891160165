var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_open_close_period_yearly") } },
    [
      _c(
        "fieldset",
        [
          _c("legend", [
            _vm._v(" " + _vm._s(_vm.$t("lbl_available_year")) + " ")
          ]),
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formState,
                rules: _vm.validationSchema,
                "label-align": "left",
                "data-testid": "calendar-year-close-period-form"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_start_year"),
                            prop: "startYear"
                          }
                        },
                        [
                          _c("a-select", {
                            staticClass: "w-100",
                            attrs: {
                              options: _vm.startYearOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_start_year")
                              }),
                              "filter-option": "",
                              "allow-clear": "",
                              "show-search": "",
                              loading: _vm.loading.year,
                              "data-testid":
                                "calendar-year-close-period-start-year"
                            },
                            model: {
                              value: _vm.formState.startYear,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "startYear", $$v)
                              },
                              expression: "formState.startYear"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_end_year"),
                            prop: "endYear"
                          }
                        },
                        [
                          _c("a-select", {
                            staticClass: "w-100",
                            attrs: {
                              options: _vm.endYearOptions,
                              "filter-option": "",
                              loading: _vm.loading.year,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_end_year")
                              }),
                              "allow-clear": "",
                              "show-search": "",
                              "data-testid":
                                "calendar-year-close-period-end-year"
                            },
                            model: {
                              value: _vm.formState.endYear,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "endYear", $$v)
                              },
                              expression: "formState.endYear"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "html-type": "button",
                        "data-testid": "calendar-year-close-period-reset"
                      },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "calendar-year-close-period-find",
                        loading: _vm.loading.find,
                        "html-type": "submit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-list", {
        staticClass: "mt-5",
        attrs: {
          "data-source": _vm.periods,
          loading: _vm.loading.find,
          "rowk-key": "year",
          "item-layout": "horizontal",
          size: "small",
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", {
                total: _vm.pagination.totalElements
              })
            },
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            total: _vm.pagination.totalElements,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit,
            onChange: _vm.onPaginationChange,
            onShowSizeChange: _vm.onPaginationSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.$t("lbl_year_period")) + " ")]
            },
            proxy: true
          },
          {
            key: "renderItem",
            fn: function(record) {
              return [
                _c(
                  "a-list-item",
                  {
                    attrs: {
                      "data-testid":
                        "calendar-year-close-period-year[" + record.year + "]"
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.$can("update", "calendar")
                          ? {
                              key: "actions",
                              fn: function() {
                                return [
                                  _c("a-switch", {
                                    attrs: {
                                      checked: record.isOpen,
                                      "data-testid":
                                        "calendar-year-close-period-toggle[" +
                                        record.year +
                                        "]"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.toggleOpen(record, $event)
                                      }
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("a-list-item-meta", {
                      attrs: { title: record.year },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "description",
                            fn: function() {
                              return _vm._l(record.modules, function(module) {
                                return _c(
                                  "a-tag",
                                  {
                                    key: module,
                                    attrs: {
                                      "data-testid":
                                        "calendar-year-close-period-module[" +
                                        record.year +
                                        "][" +
                                        module +
                                        "]"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(module) + " ")]
                                )
                              })
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }